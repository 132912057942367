.cardGrid{

   
    background: #FFFFFF;
    
    overflow-y: auto;
     margin-top:3px !important;


}
.cardGrid::-webkit-scrollbar {
    /* height: 2px; */
    width: 6px;
   
  }
  .cardGrid::-webkit-scrollbar-track {
    background: #EDF0F4;
    
  }
  
  .cardGrid::-webkit-scrollbar-thumb {
    background: #F66761;
    border-radius: 10px;
  }
  
  .OrderGrid{

    background: #303A5F;
    overflow-y: auto;
    height: 88vh;
    margin-top: 3px;
  }
  .OrderGrid::-webkit-scrollbar {
    /* height: 2px; */
    width: 6px;
   
  }
  .OrderGrid::-webkit-scrollbar-track {
    background: #EDF0F4;
    
  }
  
  .OrderGrid::-webkit-scrollbar-thumb {
    background: #F66761;
    border-radius: 10px;
  }
  
  .dialogContext::-webkit-scrollbar {
    /* height: 2px; */
    width: 6px;
   
  }
  .dialogContext::-webkit-scrollbar-track {
    background: #EDF0F4;
    
  }
  
  .dialogContext::-webkit-scrollbar-thumb {
    background: #303A5F;
    border-radius: 10px;
  }
  



  .flip-box {
    background-color: transparent;
    width: 300px;
    height: 200px;
    border: 1px solid #f1f1f1;
    perspective: 1000px; /* Remove this if you don't want the 3D effect */
  }
  
  /* This container is needed to position the front and back side */
  .flip-box-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.8s;
    transform-style: preserve-3d;
  }
  
  /* Do an horizontal flip when you move the mouse over the flip box container */
  .flip-box:hover .flip-box-inner {
    transform: rotateY(180deg);
  }
  
  /* Position the front and back side */
  .flip-box-front, .flip-box-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden; /* Safari */
    backface-visibility: hidden;
  }
  
  /* Style the front side */
  .flip-box-front {
    background-color: #bbb;
    color: black;
  }
  
  /* Style the back side */
  .flip-box-back {
    background-color: dodgerblue;
    color: white;
    transform: rotateY(180deg);
  }

  iframe {
   position: fixed !important; bottom: unset !important; right: 20px;
  top:16px !important;

  }
  /* #helpcrunch-container .helpcrunch-status-bar.helpcrunch-widget-type-icon .helpcrunch-widget-icon-block {
height: 32px !important;
width:  32px !important;
border:5px solid pink !important;

  } */
  /* .helpcrunch-widget-icon-block{
    height: 32px !important;
width:  32px !important;
border:5px solid red !important;
  }
  img {
    height: 32px !important;
    width:  32px !important;
    border:5px solid red !important;
  } */