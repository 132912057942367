* {
  -webkit-tap-highlight-color: transparent;
}

input[type="checkbox"] {
  display: none;
}

.button {
  position: relative;
  display: block;
  width: 110px;
  height: 62px;
  cursor: pointer;
  transform: scale(0.4);

  background: #cfcfcf;
  border-radius: 5px;
}

.knob {
  width: 36.6px;
  height: 40.65px;
  background-size: 62px;
  position: relative;
  top: 10px;
  background: #ffffff;
  border-radius: 3px;
  left: 10px;

  transition: 0.4s ease left, 0.4s ease background-position;
  z-index: 2;
}

.subscribe,
.alright {
  position: absolute;

  transform: translateY(-50%);
  color: #fff;
  font-size: 58px;
  font-weight: bold;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  margin-left: 39px;
  z-index: 1;
}

.alright {
  margin-left: 40px;
}

.lol-checkbox:checked + .button .knob {
  left: 60px;
  background: #ffffff;
  border-radius: 3px;
  background-position: -350px 0;
}

.lol-checkbox:checked + .button {
  background: #34ceb2;
  border-radius: 5px;
}

.orderText {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
  color: #303a5f;
}
