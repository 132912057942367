.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.swipe-to-delete {
  position: relative !important;
  padding: 0 !important;
  overflow: hidden !important;
}
.swipe-to-delete .js-delete {
  position: absolute !important;
  top: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  left: 0 !important;
  z-index: 1 !important;
}
.swipe-to-delete .js-content {
  position: relative !important;
  z-index: 2 !important;
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
}
.swipe-to-delete .js-content :first-child {
  position: relative !important;
}
.swipe-to-delete .js-transition-delete-right,
.swipe-to-delete .js-transition-delete-left,
.swipe-to-delete .js-transition-cancel {
  transition-property: left !important;
  transition-duration: 0.5s;
}
.swipe-to-delete .js-transition-delete-right {
  left: 100% !important;
}
.swipe-to-delete .js-transition-delete-left {
  left: -100% !important;
}
.swipe-to-delete .js-transition-cancel {
  left: 0 !important;
}

/* Custom styles */
.swipe-to-delete .js-delete {
  /* background: #ff5f58; */
}
.swipe-to-delete .js-delete svg {
  position: absolute;
  left: 17px;
  top: 50%;
  margin-top: -13px;
  width: 25px;
  height: 25px;
}
.swipe-to-delete .js-delete svg:first-child {
  left: auto;
  right: 17px;
}

.swipe-to-delete .js-content :first-child {
  cursor: pointer;
}

.swipe-to-delete .js-transition-delete-right,
.swipe-to-delete .js-transition-delete-left,
.swipe-to-delete .js-transition-cancel {
  transition-duration: 0.7s;
  transition-timing-function: ease-in-out;
}

.container .text-muted {
  margin: 20px 0;
}

.list-group-item {
  border-radius: 0 !important;
}

.list-group-item:last-child {
  border-width: 1px 0 !important;
}
*:not(input.field) {
  -webkit-user-select: text;
  -khtml-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}
input,
textarea {
  -webkit-user-select: text;
  -khtml-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}
